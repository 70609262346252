import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/home/runner/work/kernel-v2/kernel-v2/src/modules/layouts/default_layout.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const StatusBanner = makeShortcode("StatusBanner");
const Flash = makeShortcode("Flash");
const Card = makeShortcode("Card");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "-juego-de-patrones",
      "style": {
        "position": "relative"
      }
    }}>{`🌈 Juego de Patrones`}<a parentName="h1" {...{
        "href": "#-juego-de-patrones",
        "aria-label": " juego de patrones permalink",
        "className": "anchor-link after"
      }}><span parentName="a">{`¶`}</span></a></h1>
    <StatusBanner warning mdxType="StatusBanner">
      <p>{`Kernel no proporciona respuestas concretas. Estamos aquí para hacer mejores preguntas.`}</p>
    </StatusBanner>
    <p>{`No existen respuestas simples y únicas a todas las preguntas que nos interesan aprender y enseñar. Eso es lo que las convierte en buenas preguntas.`}</p>
    <p><strong parentName="p">{`Nuestra intención es brindarte una forma de pensar transformadora sobre las preguntas más importantes que plantea este mundo Web 3 que construimos juntos.`}</strong></p>
    <p>{`Los Kernel Fellows se diferenciarán por su capacidad para `}<strong parentName="p">{`identificar patrones`}</strong>{`.`}</p>
    <p><img parentName="p" {...{
        "src": "/images/mod0-img/banner.jpg",
        "alt": "Banner"
      }}></img></p>
    <p>{`El reconocimiento de patrones es la esencia del conocimiento. Ser más conscientes de los patrones que ya existen y del cómo elegimos interactuar con los de nuestro entorno, es lo mejor para mejorar nuestras habilidades de pensamiento crítico.`}</p>
    <p>{`En particular, esperamos que los Kernel Fellows comiencen a pensar en términos de `}<strong parentName="p">{`opuestos complementarios`}</strong>{`.`}</p>
    <p>{`En lugar de usar dualidades como 'descentralización buena, centralización mala'; o 'DeFi es innovador, fiat es aburrido', este estilo de pensamiento reconoce que no hay bien sin mal; no hay atracción sin repulsión, sin norte no hay sur, no hay arriba sin abajo.`}</p>
    <p>{`Podríamos describir esto como "pensamiento cuántico": ser capaz de contemplar `}<strong parentName="p">{`tanto`}</strong>{` el 0 como el 1 simultáneamente, `}<strong parentName="p">{`y`}</strong>{` todo el espectro de probabilidad intermedio. No se trata solo de pensar en ambos y/o ver "ambos lados" de un debate. Es ver ambos lados `}<em parentName="p">{`y`}</em>{` todo el espectro de posibles estados intermedios sin aferrarse a la certeza. Esta falta de certeza te hace humilde y es precisamente esta humildad, junto con una perspectiva más amplia del espectro total de posibles resultados lo que puede llevarte a tomar decisiones más efectivas.`}</p>
    <Flash mdxType="Flash">
      <Card mdxType="Card">
        <p>{`P: ¿El pensamiento cuántico pretende identificar la interacción de qué tipo de patrón?`}</p>
        <p>{`R: Opuestos complementarios.`}</p>
      </Card>
    </Flash>
    <p>{`Kernel Fellows `}<strong parentName="p">{`deben tener humildad.`}</strong>{` No podemos estar seguros de cuál será el efecto de nuestras acciones, solo podemos cultivar una aguda conciencia de observación que nos guiará para realizar los `}<em parentName="p">{`compromisos`}</em>{` correctos.`}</p>
    <p>{`Si quieres cambiar el mundo, el mundo inevitablemente reaccionará y te cambiará. Los resultados de las aplicaciones Web 2 lo han dejado claro. Cuanto mayor sea nuestra humildad colectiva, más amable será este intercambio a medida que Web 3 cobra vida.`}</p>
    <p>{`La idea crítica aquí es que pensar conscientemente en los compromisos y desarrollar la capacidad de tener en mente muchas probabilidades diferentes simultáneamente, sin permitir que los prejuicios personales oscurezcan nuestra visión de todos los futuros posibles, nos permite elegir el Camino Medio la mayoría de las veces.`}</p>
    <Flash mdxType="Flash">
      <Card mdxType="Card">
        <p>{`P: ¿Qué característica tiene el mayor efecto en la elección de los compromisos más constructivos?`}</p>
        <p>{`R: Humildad.`}</p>
      </Card>
    </Flash>
    <h3 {...{
      "id": "la-pregunta-honesta",
      "style": {
        "position": "relative"
      }
    }}>{`La Pregunta Honesta`}<a parentName="h3" {...{
        "href": "#la-pregunta-honesta",
        "aria-label": "la pregunta honesta permalink",
        "className": "anchor-link after"
      }}><span parentName="a">{`¶`}</span></a></h3>
    <p>{`Aquellos que han cambiado `}<strong parentName="p">{`positivamente`}</strong>{` el mundo lo hicieron porque aprendieron a negociar la complejidad, en lugar de imponer su propia voluntad sobre las cosas. Respondieron a sus propias preguntas de la manera más honesta y directa posible.`}</p>
    <p>{`Jugar con el patrón y tomar conciencia de la constante interacción de los opuestos complementarios como un medio para cultivar la presencia nos lleva a una pregunta simple:`}</p>
    <blockquote>
      <p parentName="blockquote">{`💡 ¿Cuál es la pregunta a la que eres la respuesta del universo?`}</p>
    </blockquote>
    <p>{`y su igualmente corolario directo:`}</p>
    <blockquote>
      <p parentName="blockquote">{`💡 ¿Cómo puedes responder de la manera más honesta, precisa y cariñosa?`}</p>
    </blockquote>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      